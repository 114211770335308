import { DatePipe } from '@angular/common';
import { AuthService } from './../../../../../auth/auth.service';
import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {animate, style, transition, trigger} from '@angular/animations';
import {DattaConfig} from '../../../../../app-config';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class NavRightComponent implements OnInit {
  public visibleUserList: boolean;
  public chatMessage: boolean;
  public friendId: boolean;
  public dattaConfig: any;
  academicYear: any = [];
  acYear: any = null;
  fontSizeToggle: any = 'def';
  constructor(
    config: NgbDropdownConfig,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {
    config.placement = 'bottom-right';
    this.visibleUserList = false;
    this.chatMessage = false;
    this.dattaConfig = DattaConfig.config;
  }

  ngOnInit(): any {
    this.getAcademicYear();
  }
  logout(): any {
    this.authService.logout();
    // location.reload();
    // this.authService.showSnackbar('Logout Successful', 'success');
    // localStorage.removeItem('token');
    // this.router.navigate(['/auth/signin']);
  }
  // onChatToggle(friend_id) {
  //   this.friendId = friend_id;
  //   this.chatMessage = !this.chatMessage;
  // }

  // ngDoCheck() {
  //   if (document.querySelector('body').classList.contains('datta-rtl')) {
  //     this.dattaConfig['rtl-layout'] = true;
  //   } else {
  //     this.dattaConfig['rtl-layout'] = false;
  //   }
  // }
  getAcademicYear(): any {
    // /master/academic-years/
    this.academicYear = [];
    // this.spinner = true;
    this.authService.getMethod('/master/academic-years/', null).subscribe(
      res => {
        if (res.status === true) {
          this.academicYear = res.data[0];
          this.transformDate();
        } else {
          this.authService.showSnackbar(res.message, 'error');
        }
        // this.spinner = false;
      }, error => {
        this.authService.errorMessage(this, error);
      }
    );
  }
  transformDate(): any {
    const runningFrom: any = this.datePipe.transform(this.academicYear.running.start, 'yyyy');
    const runningTo = this.datePipe.transform(this.academicYear.running.end, 'yy');
    // const upcomingFrom: any = this.datePipe.transform(this.academicYearDatas.upcoming.start, 'yyyy');
    // const upcomingTo = this.datePipe.transform(this.academicYearDatas.upcoming.end, 'yyyy');
    this.acYear = `${runningFrom}-${runningTo}`;
  }
  changeFontSize(mode): any {
    const bdy = document.getElementsByTagName('body')[0] as HTMLElement;
    if (mode === 'min') {
      // bdy.style.fontSize = '80%';
      bdy.classList.remove('f-def');
      bdy.classList.remove('f-max');
      bdy.classList.add('f-min');
      this.fontSizeToggle = 'min';
    } else if (mode === 'def') {
      // bdy.style.fontSize = '100%';
      bdy.classList.remove('f-min');
      bdy.classList.remove('f-max');
      bdy.classList.add('f-def');
      this.fontSizeToggle = 'def';
    } else if (mode === 'max') {
      // bdy.style.fontSize = '150%';
      bdy.classList.remove('f-def');
      bdy.classList.remove('f-min');
      bdy.classList.add('f-max');
      this.fontSizeToggle = 'max';
    }
  }
}
