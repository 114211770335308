<div class="navbar-brand header-logo">
  <a [routerLink]="['/dashboard/home/']" class="b-brand">
    <!-- <div class="b-bg">
      <i class="feather icon-trending-up"></i>
    </div> -->
    <!-- <img [src]="institutionDatas?.logo" [alt]="schoolDetails?.school_name" class="logo-thumbs"> -->
    <span class="b-titles">{{institutionDatas?.institution_name}}</span>
  </a>
   <!-- <a href="javascript:" class="mobile-menu" [ngClass]="{'on' : navCollapsed}" id="mobile-collapse" (click)="navCollapse()"><span></span></a> -->
</div>
