// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  newBillDesign: false,
  // test server
  // apiUrl: 'http://localhost:8000',
  apiUrl: 'http://ails.pos.apya.in:8017',

  apiKey: 'NynuPeXw.ZRIQJSDMn7ZNMal36FHfR87cWKgMEr0GxR37VA7Y93ag7lUKeGddhfOj8xGxw6TI',

  // apiUrl: 'http://3.108.227.253',
  // apiKey: 'jizTLnG3.8P7jsiCnGCUpQywdkptxfxbnErIVfhwGFVY8PugywxIBxvmTZx348OLfdVHsPe7P',
  // apiKey: 'QuEfiopl.ZCLmo9LlS7U9tNR0auubTobCqC0MKZHiJDscAwGt5IXbov3EDjLvJxQAFjkPNzeq',

  // vbes
  // apiUrl:  'http://13.233.0.200',
  // apiKey: 'U5MeEsZr.BtRlbnseTsLBwYW0ptAV7QgORibuVuq3SY9yGpIrvf1NnQR8yLWd7hz8dRCVsdfq',


  // hardwick
  // apiUrl:  'http://13.233.85.244',
  // apiKey: 'QuEfiopl.ZCLmo9LlS7U9tNR0auubTobCqC0MKZHiJDscAwGt5IXbov3EDjLvJxQAFjkPNzeq',


  school_details: {
    school_name: 'Vijaya Bharathi School',
    trust_name: 'Bala Bharathi Education Society',
    address: 'Bhuvaneshwari Nagar, T.Dasarahalli, Bangalore - 560057',
    school_logo: 'assets/images/school/school-1.jpg',
    school_cover: 'assets/images/student/vbs-cover2.jpeg',
  },
  bill_details: {
    school_name: 'Vijaya Bharathi School',
    regnd: 'Recognised by Govt of Karnataka',
    address1: 'T Dasarahalli-560057',
    address2: '',
    school_logo: 'assets/images/school/school-1.jpg',
  },

  // vbes
  // apiUrl:  'http://13.233.108.17',
  // apiKey: 'U5MeEsZr.BtRlbnseTsLBwYW0ptAV7QgORibuVuq3SY9yGpIrvf1NnQR8yLWd7hz8dRCVsdfq'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
