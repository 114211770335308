<div class="row">
    <div class="col-12">
        <app-card class="widget-statstic-plain-white" [class.fullheight]="fullheight" [hidHeader]="true"
            cardClass="widget-statstic-card card-border-c-blue">
            <div class="row">
                <div class="no-data col-12">
                    Coming Soon !!!
                </div>
            </div>
        </app-card>
    </div>
</div>